import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user: {
      namespaced: true,
      state: {
        id: 0,
        token: '',
        user: '',
        company: 'c3ntrotelecom'
      },
      mutations: {
        setToken(state, token) {
          state.token = token
        },
        setUserInfo(state, user) {
          state.user = user
        },
        setUserId(state, id) {
          state.id = id
        },
        deleteInfo(state) {
          state.token = '',
          state.user = ''
        }
      }
    },
    modal: {
      namespaced: true,
      state: {
        openModal: false,
        bodyMessage: '',
        iconType: ''
      },
      mutations: {
        setModal(state, options) {
          state.openModal = !state.openModal
          state.bodyMessage = options?.bodyMessage || ''
          state.iconType = options?.iconType || ''
        }
      }
    }
  }
})
