import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/',
    component: () => import('@/views/PortalView.vue'),
    children: [
      {
      path: '/',
        redirect: '/formulario'
      },
      {
        path: '/formulario',
        component: () => import('@/components/Form/FormularioPrincipal.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
}) 

export default router
